import React, { useState } from 'react';
import { ChallengeData2 } from '../ChallengeData2/index';
import ProgressBar from '../Common/ProgressBar/index';
import { TileStrips } from '../Common/TileStrips/index';
import { GetStarted } from '../GetStarted/index';
import { InitialPage } from '../InitialPage/index';
import { PasswordCriteria } from '../PasswordCriteria/index';
import { PulseSecureInstruction } from '../PulseSecureInstruction/index';
import { ResponseCode } from '../ResponseCode/index';
import { TemporaryPassword } from '../TemporaryPassword/index';
import { SubHeader } from '../../../components';
import { useNavigate } from 'react-router-dom';
import * as Api from '../../../api';
import { breadcrumbPasswordReset } from '../../../constants';
import { useDispatch } from 'react-redux';

export function LandingPage() {
    const [activeTile, setActiveTile] = useState("InitialPage");
    const [responseCode, setResponseCode] = useState("");
    const [tempPassword, setTempPassword] = useState("");
    const [activeStrips, setActiveStrips] = useState(1);
    const [stripLabel, setStripLabel] = useState("");
    const [progressBarShow, setProgressBarShow] = useState(false);
    const [headMessage, setHeadMessage] = useState("Please Wait");
    const [description, setDescription] = useState("Please Wait...");
    const [totalStrips, setTotalStrips] = useState(6);
    const [stripscls, setStripscls] = useState('');
    const [isSecureDoc, setIsSecureDoc] = useState(true);
    const [successPercentage, setSuccessPercentage] = useState(20);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function loadTile(tileName) {
        setActiveTile(tileName);
    }

    function handleClose(event, reason) {
        if (reason && reason === "backdropClick")
            return;
        setProgressBarShow(false);
    }

    function resetPassword() {
        setProgressBarShow(true);
            Api.Post(dispatch,'api/v1/userProfile/resetpassword', null, null)
            .then(response => {
                intervalToSetupTemporaryPassword(setProgressBarShow, setDescription, setSuccessPercentage, loadTile);
                setTempPassword(response);
            })
            .catch((error) => {
                setProgressBarShow(false);
                navigate(`/passwordreset/error`);
        });

    }

    const intervalToSetupTemporaryPassword = async (setProgressBarShow, setDescription, setSuccessPercentage, loadTile) => {
        setDescription("");
        setSuccessPercentage(40);
        setDescription("Please wait while we set things up for you...");
        await new Promise(resolve => setTimeout(resolve, 5000)); // 5 sec

        setSuccessPercentage(60);
        setDescription("This usually takes not more than a minute...");
        await new Promise(resolve => setTimeout(resolve, 5000)); // 5 sec

        setSuccessPercentage(90);
        setDescription("Almost there...");
        await new Promise(resolve => setTimeout(resolve, 10000)); // 10 sec

        setProgressBarShow(false);
        loadTile("TemporaryPassword");
    };

    return (
        <div>
            <div>
                <SubHeader breadcrumbs={breadcrumbPasswordReset} />
                <ProgressBar aria-label="progressBar" open={progressBarShow} handleClose={handleClose} headMessage={headMessage}
                description={description} successPercentage={successPercentage} />
                {activeTile !== "InitialPage" &&
                    <TileStrips totalStrips={totalStrips}
                        activeStrips={activeStrips}
                        stripsCls={stripscls}
                        stripLabel={stripLabel}
                        setStripLabel={setStripLabel}
                    />}

                {activeTile === "InitialPage" &&
                    <InitialPage loadTile={loadTile} data-testid="btnInitialPage"
                        setActiveStrips={setActiveStrips}
                        setTotalStrips={setTotalStrips}
                        SetStripsCls={setStripscls}
                        setIsSecureDoc={setIsSecureDoc}
                        resetPassword={resetPassword}
                    />}

                {activeTile === "GetStarted" &&
                    <GetStarted loadTile={loadTile}
                        setActiveStrips={setActiveStrips}
                        setStripLabel={setStripLabel}
                    />}

                {activeTile === "ChallengeData2" &&
                    <ChallengeData2 loadTile={loadTile}
                        setResponseCode={setResponseCode}
                        setActiveStrips={setActiveStrips}
                        setStripLabel={setStripLabel}
                        setProgressBarShow={setProgressBarShow}
                        setHeadMessage={setHeadMessage}
                        setDescription={setDescription}
                        setSuccessPercentage={setSuccessPercentage}
                    />}

                {activeTile === "ResponseCode" &&
                    <ResponseCode
                        responseCode={responseCode}
                        setActiveStrips={setActiveStrips}
                        setStripLabel={setStripLabel}
                        setHeadMessage={setHeadMessage}
                        resetPassword={resetPassword}
                        loadTile={loadTile}
                    />}

                {activeTile === "TemporaryPassword" &&
                    <TemporaryPassword loadTile={loadTile}
                        tempPassword={tempPassword}
                        setActiveStrips={setActiveStrips}
                        setStripLabel={setStripLabel}
                        isSecureDoc={isSecureDoc}
                    />}

                {activeTile === "PasswordCriteria" &&
                    <PasswordCriteria loadTile={loadTile}
                        tempPassword={tempPassword}
                        setActiveStrips={setActiveStrips}
                        setStripLabel={setStripLabel}
                        isSecureDoc={isSecureDoc}
                    />}

                {activeTile === "PulseSecureInstruction" &&
                    <PulseSecureInstruction loadTile={loadTile}
                        setActiveStrips={setActiveStrips}
                        setStripLabel={setStripLabel}
                        isSecureDoc={isSecureDoc}
                    />}
            </div>
        </div>
    );
}
