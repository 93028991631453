import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    isError: false,
    errorDetails: [],
    isSuccess: false,
    successMessage: [],
    isInfo: false,
    infoMessage: [],
    errorMessage: []
};

export const notificationSlice = createSlice({
    name: 'notificationDetails',
    initialState,
    reducers: {
        showErrorNotification: (state, { payload }) => {
            return {
                ...state,
                isError: true,
                errorMessage: payload,
                errorDetails: payload,
                isSuccess: false,
                isInfo: false,
            }
        },
        hideNotification: (state) => {
            return {
                ...state,
                isError: false,
                errorDetails: null,
                isSuccess: false,
                successMessage: null,
                isInfo: false,
                infoMessage: null
            }
        },
        showSuccessNotification: (state, { payload }) => {
            return {
                ...state,
                isSuccess: true,
                errorDetails: null,
                successMessage: payload,
                isError: false,
                isInfo: false,
            }
        },

        showInfoNotification: (state, { payload }) => {
            return {
                ...state,
                isInfo: true,
                infoMessage: payload,
                isSuccess: false,
                isError: false,
            }
        }
    }
});

export const { showErrorNotification, hideNotification, showSuccessNotification, showInfoNotification } = notificationSlice.actions;
export const successLoadingStatus = (state) => state.notificationDetails;
export default notificationSlice.reducer;
