import React from 'react';
import PropTypes from 'prop-types';
import { Box, Toolbar,Chip } from '@mui/material';
import styles from './SubHeader.module.scss';
import { styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import HelpIcon from '@mui/icons-material/Help';
import { useNavigate } from 'react-router-dom';

const CustomToolbar = styled((props) => <Toolbar {...props} />)(
  () => ({
    minHeight: '0!important',
    padding: '0!important',
  }),
);

const propTypes = {
  breadcrumbs: PropTypes.any,
  faq:PropTypes.bool
};

/**
 * Application Header component according to wf standards
 * @param {*} routerItems : The links with routes to be displayed in header
 * @returns Header component
 */
const SubHeader = ({ breadcrumbs,faq=true }) => {

  const isActive = window.localStorage.getItem('isActive');
  const navigate = useNavigate();

  return ( 
    <Box className={styles['headerContainer']}>
      <CustomToolbar className={styles['navbar']}>
        <Box className={styles['brandContainer']}>
          <Stack spacing={2}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
          </Stack>
        </Box>
        {isActive &&  faq && 
          <Chip onClick={() =>  navigate(`/faq`)} icon={<HelpIcon />} label="FAQ" />
        }
      </CustomToolbar >
    </Box >
  );
};

SubHeader.propTypes = propTypes;
export default SubHeader;
