import React from 'react';
import { Box, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { SubHeader } from '../../../components';
import { breadcrumbMainApp } from '../../../constants';
import OprCardDetails from '../../../components/OprCard/index';
import prSrc from '../../../assets/Images/pass_reset.svg';
import PROptionMenus from '../../PasswordReset/PROption/index';

export default function DWPSApps() {
    return (
        <>
            <Helmet>
                <title>Self Service Portal - Apps</title>
            </Helmet>
            <SubHeader breadcrumbs={breadcrumbMainApp} />
            <Box sx={{ margin: '1rem', marginTop: '1.5rem' }}>
                <Grid container columnSpacing="1.5rem" rowSpacing="1rem">
                    <Grid item xs={12} sm={6} md={6} lg={3}>
                        <OprCardDetails
                            CardName="AD-ENT Account"
                            avatarAlt="AD-ENT Account tile"
                            avatarSrc={prSrc}
                            children={<PROptionMenus />}>
                        </OprCardDetails>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}