import React, { useState, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { ChallengeData2Input } from '../Common/ChallengeData2Input'
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";
import * as Api from '../../../api';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

const propTypes = {
    loadTile:PropTypes.any,
    setResponseCode:PropTypes.any,
    setActiveStrips:PropTypes.any,
    setStripLabel:PropTypes.any,
    setProgressBarShow:PropTypes.any,
    setHeadMessage:PropTypes.any,
    setDescription:PropTypes.any,
    setSuccessPercentage:PropTypes.any,
};

ChallengeData2.propTypes = propTypes;

export function ChallengeData2({ loadTile, setResponseCode, setActiveStrips, setStripLabel, setProgressBarShow, setHeadMessage, setDescription, setSuccessPercentage }) {
   
    const [validationerror, setValidationerror] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const methods = useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        setActiveStrips(2);
        setStripLabel('Challenge Data2');
        setHeadMessage("Getting Response code");
        setDescription("Please wait...");
    }, []);

    const onSubmit = methods.handleSubmit(data => {
        if (data) {
            if (!data["challengeData2"]?.match(/^[0-9\s]*$/)) {
                setValidationerror(true);
                setErrorMessage("challengeData2 is invalid");
                return false;
            }
            if(data["challengeData2"]===null || data["challengeData2"]===""){
                setValidationerror(true);
                setErrorMessage("challengeData2 is mandatory.");
                return false;
            }
            let requestData = {
                'ChallengeData2': data["challengeData2"].replace(/ /g, '')
            };
            submitChallenceData2(requestData, navigate);
            setValidationerror(false);
            setErrorMessage(null);
            return true;
        }
        return false;
    })


    function submitChallenceData2(requestData, navigate) {
        setProgressBarShow(true);
        setSuccessPercentage(90);

        Api.Post(dispatch,'api/v1/userProfile/challengecode', null, requestData)
            .then(response => {
                setProgressBarShow(false);
                setResponseCode(response)
                loadTile("ResponseCode");
            })
            .catch((error) => {
                setProgressBarShow(false);
                navigate(`/passwordreset/error`);
        });
    }

    return (
        <div>
            <Helmet>
                <title>Self Service Portal - Challenge Data</title>
            </Helmet>
            <div className="bg-panel">
                <FormProvider {...methods}>
                    <form onSubmit={e => e.preventDefault()} noValidate style={{justifyItems: 'center',display: 'grid'}}>
                        <div className="card-panel">
                            <div className="step">
                                <span>Step 3</span>
                            </div>
                            <div className="step-instruction">
                                Enter the 'Challenge data 2' seen on your Wells Fargo device below to obtain a Response.
                            </div>
                            <ChallengeData2Input validationError={validationerror} errorMessage={errorMessage} />
                        </div>

                        <div className="btn-container">
                            <input type="button" className="button-secondary" value="Back" onClick={()=>{loadTile("GetStarted")}} />
                            <input type="button" className="button-primary" value="Next"  onClick={onSubmit} />
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div >
    );
}

