import React from 'react';
import { useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';

const propTypes = {
    validationError:PropTypes.any,
    errorMessage:PropTypes.any
};

ChallengeData2Input.propTypes = propTypes;

export function ChallengeData2Input({ validationError,errorMessage }) {
    const validation = {
        required: {
            value: false,
            message: 'required',
        }
    }
    const { register } = useFormContext()

    return (
        <div>
            <div className="input-group">
                <label htmlFor="challengeData2">Challenge data 2</label>
                <InputMask mask="99 99 99 99 99 99 99 99 99" id="challengeData2" type="text"
                    className="custom-txtbox" autoComplete="off" {...register("challengeData2", validation)}></InputMask>
                {validationError && <div style={{ color: '#D71E28',marginTop:'5px' }}>{errorMessage}</div>}
            </div>
        </div>
    );
}
