import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import disclaimerPng from '../../../../assets/FAQ/Disclaimer.png';
import Skeleton from '@mui/material/Skeleton';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const propTypes = {
    dialogOpen: PropTypes.any,
    close:PropTypes.any,
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function DisclaimerDialog({dialogOpen,close}) {
    const [imageloaded, setImageloaded] = useState(false);

    const handleClose = () => {
        close();
    };

    const onLoad = () => {
        setImageloaded(true);
    };

    return (
        <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={dialogOpen}
        >
            <DialogTitle sx={{ m: 0, p: 2, alignSelf: 'center' }} id="customized-dialog-title">
                Disclaimer
            </DialogTitle>
            <IconButton data-testid='btnClose'
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent dividers>
                {!imageloaded && <Skeleton height="30rem" width="100%" animation="wave" variant="rectangular" />}
                <Box sx={{ display: !imageloaded ? 'none' : 'flex', justifyContent: 'center' }}>
                    <img onLoad={() => onLoad()} style={{ width: '100%', height: '100%' }} src={disclaimerPng} alt='Disclaimer Notification'></img>
                </Box>

                <Typography gutterBottom>
                    Confirm your Wells Fargo device is connected to the internet by selecting 
                    the Wi-Fi icon at the bottom right of the orange SecureDoc screen or by selecting 
                    the monitor icon at the bottom right of the Windows login screen as shown above.
                </Typography>
            </DialogContent>
        </BootstrapDialog>
    );
}
