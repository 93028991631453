import React, { useEffect } from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import firstQuestion from '../assets/FAQ/1.png';
import secondQuestion from '../assets/FAQ/2.png';
import fifthQuestion from '../assets/FAQ/5.png';

export const HYPR_CAPTION='Enroll for HYPR Passwordless Authentication';
export const HYPR_SUBCAPTION=<>Install <b>HYPR APP</b> in your mobile before proceeding</>
export const APPSTORE_CAPTION='Find the app in your respective App Store';
const STATUS_PROCESSING = 0;
const STATUS_SUCCESS = 1;
const STATUS_FAILED = 2;

export const ExceptionHandlers = Object.freeze({
    error: 'Error',
    success: 'Success',
    customErrorMessage: 'Please wait a moment before you try again.',
    customSuccessMessage: 'Operation Completed Successfully.',
    orderSuccessMessage: ' submitted successfully',
});

export const SUCCESS = "success";
export const FAILURE = "danger";

const ROUTER_ITEMS = [
    { src: '/vdijourney/contact', text: 'Contact Us', reactRoute: true },
    { src: 'https://confluence.wellsfargo.net/display/WXPSGA/FAQ', text: 'FAQ' }
];

const getImageUrl = (name) => {
    return new URL(`../assets/FAQ/${name}`, import.meta.url).href
}

export const faqData = [
    { question: '1. How can I reset my password?', answer: 'Answer: After successful logging in, you will be presented with an option labeled “Password Reset”. Please click on it, then follow the instructions to reset your password.',pic:firstQuestion },
    { question: '2.	How will I know my Wells Fargo Corporate device has WinMagic SecureDoc installed?', answer: 'Answer: When you switch on the Wells Fargo’s corporate device, if you are presented with an orange screen asking for username and password, then it confirms that WinMagic SecureDoc is installed.',pic:secondQuestion },
    { question: '3.	Does this application reset my QA-ENT password?',answer:'Answer: No. This resets AD-ENT password only.'},
    { question: '4.	Does this application reset my SecureID?',answer:'Answer: No.'},
    { question: '5.	Should I connect to Pulse Secure, after successful reset and login?',answer:'Answer: Yes, after your password is reset, you will need to login to Pulse Secure in case of Windows, or Ivanti Secure Access Client for Mac OS, so that passwords are synchronized, and you obtain to the Wells Fargo network.',pic:fifthQuestion},
    { question: '6.	Should the Wells Fargo corporate device be connected to Wi-Fi or a working LAN to reset my password?',answer:'Answer: Yes, the device will not accept the new password, unless it is connected to the network.'}
];

const COLOR_PRIMARY = '#0070c9';



const done_item = {
    success: true,
    Icon: CheckOutlinedIcon,
    header: 'You are all set up!',
    content: (() => {
        const Content = () => {
            window.localStorage.setItem('checksPassed', true);
            const WAVE_URL = 'https://wavea.wellsfargo.com/';

            useEffect(() => {
                const id = setTimeout(() => window.location.href = WAVE_URL, 3000);
                return () => clearTimeout(id);
            }, []);
            return (
                <>
                    Redirecting to wave client. If it doesn't launch automatically, click
                    {' '}<a target="_self" rel="noopener noreferrer" href={WAVE_URL}>here</a>.
                </>
            );};
        return <Content />;
    })(),
};

export const breadcrumbMainApp = [
    <p key="1" color="inherit">
        Welcome! Reset your Windows AD-ENT password or unlock your account here
    </p>,
];

export const breadcrumbVdiJourney = [
    <Link key="1" underline="none" color="inherit" href="/apps">
        Home
    </Link>,
    <Typography key="2" color="text.primary">
        WAVE VDI
    </Typography>,
];

export const breadcrumbFAQ = [
    <Link key="1" underline="none" color="inherit" href="/apps">
        Home
    </Link>,
    <Typography key="2" color="text.primary">
        FAQ
    </Typography>,
];

export const breadcrumbPasswordReset = [
    <Link key="1" underline="none" color="inherit" href="/apps">
        Home
    </Link>,
    <Typography key="2" color="text.primary">
        Password Reset
    </Typography>,
];

export const breadcrumbRSA = [
    <Link key="1" underline="none" color="inherit" href="/apps">
        Home
    </Link>,
    <Typography key="2" color="text.primary">
        RSA SucureId
    </Typography>,
];


export const EXE_VERSION = '1.0.1';
export const OLD_EXE_MESSAGE = 'You are running an older version of executable. Please run the latest executable to continue';
export { ROUTER_ITEMS, COLOR_PRIMARY,STATUS_FAILED, STATUS_PROCESSING, STATUS_SUCCESS };
export { done_item };


