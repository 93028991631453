import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  disabled:PropTypes.bool,
  customStyles:PropTypes.any,
  variant:PropTypes.any,
  endIcon:PropTypes.any,
  startIcon:PropTypes.any,
};

const CustomButton = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  textAlign: 'center',
  fontSize: '0.8rem',
  padding: '6px 12px',
  border: '1px solid',
  borderRadius: '4px',
  lineHeight: 1.5,
  backgroundColor: 'white',
  color: '#d71e28',
  borderColor: '#d71e28',
  '&:hover': {
    backgroundColor: '#d71e28',
    borderColor: '#d71e28',
    boxShadow: 'none',
    color: 'white'
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#d71e28',
    borderColor: '#d71e28',
    color: 'white'
  }
});


/**
 * React Button component accoording to WF style guide
 * @param text : Button text
 * @param onClick: Callback function on button click
 * @param disabled: Flag to disable button
 * @param customStyles: Custom css styles to be passed to the styles prop
 * @param variant: Variant can be text/outlined/contained. Default: text
 * @param endIcon: Icon at the end of the button
 * @param startIcon: Icon at the start of the button
 * @returns A Button component
 */
function WfButton({ text, onClick, disabled, customStyles, variant, endIcon, startIcon }) {
  let style = customStyles;
  if (disabled) {
    style = {
      ...style,
      boxShadow: 'none',
      backgroundColor: 'white',
      borderColor: 'gray',
      color: 'gray',
    };
  }

  return (
    <CustomButton style={style} onClick={onClick} disabled={disabled} variant={variant} endIcon={endIcon} startIcon={startIcon}>
      {text}
    </CustomButton>
  );
}

WfButton.propTypes = propTypes;

export { WfButton as Button };
