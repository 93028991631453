import React, { useEffect } from 'react';
import './Notifier.scss';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const propTypes = {
  type:PropTypes.any,
  Message:PropTypes.any
};


/* * Notification componenet which displays success/failure notification on the app top right side (This last around 3 seconds)
 * @param {*} props :Contains the Notification type and message
 * @returns Notifier component
 */
export const Notifier = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    enqueueSnackbar(props.Message, { anchorOrigin: { horizontal: 'right', vertical: 'bottom' },
      variant: props.type === "success" ? "success" : "warning", autoHideDuration: 15000 });
  })

  return (
    <></>
  );
};

Notifier.propTypes = propTypes;
export default Notifier;
