import axios from 'axios';
import { showErrorNotification } from '../redux/Notification';
import {hideLoader} from '../redux/Loader';

const BaseURL = process.env.REACT_APP_API_BASE_URL;
export const Get = async (dispatch,endpoint, params = null, body = null, options = {}) => {
    const URL = BaseURL.concat(endpoint);
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common = {
            'SessionId': window.localStorage.getItem('sessionId'),
            'X-CSRF-TOKEN': window.localStorage.getItem('csrf_token_id'),
            'Cache-Control': 'no-cache',
        };
        axios.get(
            URL,
            {
                withCredentials: true,
                ...options,
                params: params
            },
            body,
        ).then(({ status, data }) => {
            if (status === 200) {
                resolve(data);
            } else {
                reject(new Error('error'));
            }
        }).catch((error) => {
            dispatch(hideLoader());
            error?.response?.data &&  dispatch(showErrorNotification(error?.response?.data));
            reject(new Error('error'));
        });
    });
}

export const Post = async (dispatch,endpoint, params = null, body = null) => {
    return new Promise((resolve, reject) => {
        axios.defaults.headers.common = {
            'SessionId': window.localStorage.getItem('sessionId'),
            'Cache-Control': 'no-cache',
            'X-CSRF-TOKEN': window.localStorage.getItem('csrf_token_id'),
        };
        axios.post(
            BaseURL.concat(endpoint),
            body,
            {
                params: { params },
                withCredentials: true,
            }
        ).then(({ status, data }) => {
            if (status === 200) {
                resolve(data);
            } else {
                reject(new Error('error'));
            }
        }).catch((error) => {
            dispatch(hideLoader());
            error?.response?.data &&  dispatch(showErrorNotification(error?.response?.data));
            reject(new Error('error'));
        });
    })
}
