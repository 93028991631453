import React from 'react';
import { Button } from '@mui/material';
import styles from './Button.module.scss'
import PropTypes from 'prop-types';

const propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    disabled:PropTypes.any,
    variant:PropTypes.any,
    style:PropTypes.any,
    type:PropTypes.any
};

export const WfButton=({ text=null, onClick=null, disabled=null, variant=null, style=null, type=null })=> {
    return (
        <Button style={style} type={type} className={disabled ? styles['disabled'] : styles[variant]} onClick={onClick} disabled={disabled} variant={variant}>
            {text}
        </Button>
    );
}

WfButton.propTypes = propTypes;
export default WfButton;
