import React, { useState, useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Button';
import {
  FormControl,
  OutlinedInput,
  InputAdornment,
  InputLabel
} from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomOutlinedInput = styled(OutlinedInput)({
  '& .MuiInputBase-input': {
    padding: '0.63rem',
    fontSize: '0.77rem',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'inherit',
    width: '100%',
  },
});

const textFieldPropTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  styles: PropTypes.any,
};

const TextField = forwardRef(({ title, value, defaultValue, controlledValue, placeholder, onBlur, onChange, styles }, ref) => {
  const [textFieldValue, setTextFieldValue] = useState(defaultValue ?? value ?? '');
  const [focussed, setFocussed] = useState(false);

  useEffect(() => {
    setTextFieldValue(value);
  }, [value]);

  return (
    <FormControl title={title} variant="outlined" sx={{ ...styles }} size="small">
      {placeholder && (
        <InputLabel
          htmlFor={title}
          sx={{
            font: 'inherit',
            fontSize: '0.77rem',
            color: 'rgb(51,51,51)',
          }}
        >
          {!focussed && placeholder}
        </InputLabel>
      )}
      <CustomOutlinedInput
        id={title}
        value={controlledValue ?? textFieldValue}
        inputRef={ref}
        onChange={(event) => {
          setTextFieldValue(event.target.value);
          typeof (onChange) === 'function' && onChange(event);
        }}
        onBlur={(event) => {
          typeof (onBlur) === 'function' && onBlur(event);
          if (!event.target.value) {
            setFocussed(false);
          }
        }}
        onFocus={(event) => {
            setFocussed(true);
        }}
      />
    </FormControl >
  );
});

const textFieldWithButtonPropTypes = {
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

const TextFieldWithButton = ({ title, placeholder, buttonText, onClick, change }) => {
  return (
    <FormControl title={title} variant="outlined" sx={{ width: '90%' }} size="small">
      <InputLabel
        htmlFor={title}
        sx={{
          font: 'inherit',
          fontSize: '0.77rem',
          color: 'rgb(51,51,51)',
          maxWidth: 'calc(90% - 4.7rem)',
        }}
      >
        {placeholder}
      </InputLabel>
      <CustomOutlinedInput
        id={title}
        onChange={({ target }) => {
          typeof (change) === 'function' && change(target.value);
        }}
        endAdornment={
          <InputAdornment position="end" sx={{ maxWidth: '60%' }}>
            <div
              aria-label="toggle password visibility"
              edge="end"
              sx={{ padding: '0.5rem 0.5rem 0.5rem 0' }}>
              <Button
                customStyles={{ height: '1.5rem', fontSize: '0.7rem', width: '4.2rem', }}
                text={buttonText}
                onClick={onClick}
              />
            </div>
          </InputAdornment>
        }
        label={title}
      />
    </FormControl >
  );
};


TextFieldWithButton.propTypes = textFieldWithButtonPropTypes;
TextField.propTypes = textFieldPropTypes

export { TextFieldWithButton, TextField };
