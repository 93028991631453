import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';

const propTypes = {
    open:PropTypes.any,
    handleClose:PropTypes.any,
    headMessage:PropTypes.any,
    description:PropTypes.any,
    successPercentage:PropTypes.any,
};

ProgressBar.propTypes = propTypes;

export default function ProgressBar({ open, handleClose, headMessage, description, successPercentage }) {
    let pendingPercentage = 100 - (successPercentage + 1);

    return (
        <div>
            <Modal open={open} onClose={handleClose}
                disableEscapeKeyDown={true}
                data-testid="btnprogessBar"
            >
                <Box className="modal-content">
                    <div className="modal-body">
                        <div id="progressHeaderText" className="text-center progress-header">{headMessage}</div>
                        <div className="progress">
                            <div id="progressSucess" className="progress-bar progress-success" aria-role="progress" style={{ width: successPercentage + '%' }} ></div>
                            <div id="progressPending" className="progress-bar progress-pending" aria-role="progress-pending" style={{ width: pendingPercentage + '%' }} ></div>
                        </div>
                    </div>
                    <div id="progressMessage" className="text-center progress-message">
                        {description}
                    </div>
                </Box>
            </Modal>
        </div>
    );
}
