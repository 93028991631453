import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import styles from './ConfirmationBox.module.scss';

const propTypes = {
    openConfirmationBox: PropTypes.any,
    handleConfirmationBoxCancel: PropTypes.any,
    handleProceed: PropTypes.any,
    dialogTitle: PropTypes.any,
    dialogContentText: PropTypes.any,
};

ConfirmationBox.propTypes = propTypes;

export default function ConfirmationBox({ openConfirmationBox, handleConfirmationBoxCancel, handleProceed, dialogTitle, dialogContentText }) {
    return (
        <div>
            <Dialog
                open={openConfirmationBox}
                onClose={handleConfirmationBoxCancel}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">
                    {dialogTitle}
                </DialogTitle>
                <DialogContent className={styles['dialog-content']}>
                    <div className={styles['dialog-text']}>
                        {dialogContentText}
                    </div>
                    <input type="submit" className="button-primary shift-right" value="Continue" onClick={handleProceed} autoFocus />
                    <input type="submit" className="button-secondary shift-right" value="Cancel" onClick={handleConfirmationBoxCancel} />
                </DialogContent>
            </Dialog>
        </div>
    );
}