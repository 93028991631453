import * as React from 'react';
import { Box, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { SubHeader } from '../../../components';
import { breadcrumbFAQ, faqData } from '../../../constants';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './FAQ.module.scss'

export default function FAQ() {
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <>
            <Helmet>
                <title>Self Service Portal - FAQ</title>
            </Helmet>
            <SubHeader breadcrumbs={breadcrumbFAQ} faq={false} />
            <Box sx={{ display: 'flex', margin: '1rem', justifyContent: 'center' }}>
                <Grid container className={styles['heading']}>
                    <Grid item xs={12} sm={12} md={12}>
                        {(faqData).map((entry) => {
                            return (
                                <Accordion sx={{ margin: '0px 0 !important' }} key={entry.question} expanded={expanded == entry.question} onChange={handleChange(entry.question)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} id={entry.question}>
                                        <Typography id="accordingSummary">{entry.question}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <p>
                                            {entry.answer}
                                        </p>
                                        <p style={{justifyContent: 'center',display: 'flex',marginTop:'10px'}}>
                                            {entry.pic &&
                                            <img alt="steps explained"  src={entry.pic} href={entry.pic}></img>
                                            }
                                        </p>
                                    </AccordionDetails>
                                </Accordion>
                                )
                        })}
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}
