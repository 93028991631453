import { combineReducers } from 'redux';
import selfServiceReducer from './PasswordReset/Slice';
import notificationReducer from './Notification';
import loaderReducer from './Loader';

const rootReducer = combineReducers({
  selfServiceDetails: selfServiceReducer,
  notificationDetails: notificationReducer,
  loaderDetails: loaderReducer,
});

export default rootReducer;
