import React, { useState, useEffect, useCallback } from 'react';
import { Popover, Button, Backdrop } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from './PROption.module.scss';
import ConfirmationBox from '../../../components/ConfirmationBox/index';
import { unlockADAccount } from '../../../redux/PasswordReset/Slice';
import { useNavigate } from 'react-router-dom';
import { loadingSelector } from '../../../redux/Loader';
import { showSuccessNotification } from '../../../redux/Notification';

export default function PROptionMenus() {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openUnlockConfBox, setOpenUnlockConfBox] = useState(false);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    }
    function unlockPRAccount() {
        setOpenUnlockConfBox(true);
        setAnchorEl(null);
    }
    function handleUnlockCancel() {
        setOpenUnlockConfBox(false);
    }
    function handleUnlockAccount() {
        dispatch(unlockADAccount());
        dispatch(showSuccessNotification("Unlocking your account.."));
        setAnchorEl(null);
        setOpenUnlockConfBox(false);
    }

    const PasswordResetLoad = () => {
        navigate(`/passwordreset/landingpage`);
    }

    return (
        <>
            <ConfirmationBox data-testid="unlock-conf"
                openConfirmationBox={openUnlockConfBox}
                handleConfirmationBoxCancel={handleUnlockCancel}
                handleProceed={handleUnlockAccount}
                dialogContentText={"You are about to unlock your AD Account. Are you Sure want to continue?"}
            />

            <div>
                <Button
                    id="pr-option-button"
                    aria-controls={open ? 'pr-option-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={handleClick}
                    endIcon={<KeyboardArrowDownIcon />}
                    className={ styles['pr-option-button']}
                >
                    Options
                </Button>
                <Popover
                    id='pr-menu'
                    open={open}
                    anchorEl={anchorEl}
                    onClose={() => handleClose()}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <div className={styles['pr-option-container']}>
                        <div className={styles['pr-option-menu']} onClick={() => PasswordResetLoad()}>Reset Password</div>
                        <div className={styles['pr-option-menu']} onClick={() => unlockPRAccount()}>Unlock Account</div>
                    </div>
                </Popover>
            </div>
            
        </>
    );
}