import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    loadTile:PropTypes.any,
    tempPassword:PropTypes.any,
    setActiveStrips:PropTypes.any,
    setStripLabel:PropTypes.any,
    isSecureDoc:PropTypes.any,
};

PasswordCriteria.propTypes = propTypes;

export function PasswordCriteria({ loadTile, tempPassword, setActiveStrips, setStripLabel, isSecureDoc }) {
    let step=(isSecureDoc?6:2);
    const [isCollapse, setIsCollapse] = useState(false);

    function getPulseSecureLogin() {
        loadTile("PulseSecureInstruction");
    }

    function toggleCollapse(val) {
        setIsCollapse(!val);
    }

    function setActiveTile() {
        if (isSecureDoc) {
            setActiveStrips(5);
        } else {
            setActiveStrips(2);
        }
    }

    useEffect(() => {
        setActiveTile();
    setStripLabel('Change Password');
    }, []);

    return (
        <div>
            <div className="bg-panel">
                <div className="card-panel">
                    <div className="step">
                        <span>Step {step}</span>
                    </div>
                    <div className="step-instruction">
                            On your Wells Fargo computer, enter the temporary password into the Old Password field, enter your new password, and then confirm your new password.
                    </div>
                    <div className="display-group">
                        <label htmlFor="code">Temporary Password</label>
                        <div className="code">
                            <label>{tempPassword}</label>
                        </div>
                    </div>

                    <div role="button" data-toggle="collapse" data-target="#criteria" onClick={() => toggleCollapse(isCollapse)}>
                        {isCollapse && <div id="show-btn" className="collapse-toggle-btn">Show new password criteria's <div className="collapse-toggle-icon">+</div></div>}
                        {!isCollapse && <div id="hide-btn" className="collapse-toggle-btn collapse">
                            Hide new password criteria's <div className="collapse-toggle-icon">-</div>
                        </div>}

                    </div>
                    {!isCollapse && <div id="criteria" className="collapse">
                        <div className="text-left pwd-criteria">
                            <div>*Must contain exactly 16 characters</div>
                            <div>*Must Contain least one upper-case letter, one lower-case, one number</div>
                            <div>*May contain a special character: @ # $</div>
                            <div>New password must not: </div>
                            <div>*Contain three or more repeating characters</div>
                            <div>*Contain three or more consecutive letters of your name or user ID (i.e. john.smith - joh, smi, ith) </div>
                            <div>*Contain or be a variation of a commonly used or easily guessed password</div>
                            <div>*Be a password you used previously</div>
                        </div>
                    </div>}
                </div>

                <div className="btn-container">
                    <input type="button" className="button-primary" value="Next" onClick={() => getPulseSecureLogin()} />
                </div>
            </div>
        </div>
    );
}
