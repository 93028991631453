import React from 'react';
import { Box, Toolbar, Button } from '@mui/material';
import styles from './Header.module.scss';
import logo from '../../assets/Images/Logo_White.svg';
import { styled } from '@mui/material/styles';
import LogOff from '../../components/LogOff';
import { useDispatch } from 'react-redux';
import { validateTokenAndRedirect } from "../../redux/PasswordReset/Slice";

const CustomToolbar = styled((props) => <Toolbar {...props} />)(
  () => ({
    minHeight: '0!important',
    padding: '0!important',
  }),
);
/**
 * Application Header component according to wf standards
 * @returns Header component
 */
const Header = () => {
  const dispatch = useDispatch();

  const validateAndRedirect = () => {
    dispatch(validateTokenAndRedirect());
  }

  return (
    <Box className={styles['headerContainer']}>
      <CustomToolbar className={styles['navbar']}>
        <Box className={styles['brandContainer']}>
          <Button variant='text' onClick={() => validateAndRedirect()} className={styles['logo']} >
            <img alt="software type" src={logo} style={{ width: '100%' }}></img>
          </Button>
          <Box className={styles['vr']}></Box>
          <Button variant='text' onClick={() => validateAndRedirect()} className={styles['appName']} style={{textTransform: 'none',fontSize:'large'}}>Self Service Portal</Button>
        </Box>
        <Box>
          <LogOff />
        </Box>
      </CustomToolbar>
    </Box>
  );
}

export default Header;
