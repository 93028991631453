import React from 'react';
import PropTypes from 'prop-types';
import { easings } from 'react-animation';
import 'react-animation/dist/keyframes.css';
import { Card, CardContent, Grid, Box } from '@mui/material';
import { Button } from '../../components/Button';
import styles from './Card.module.scss';
import Avatar from '@mui/material/Avatar';

const propTypes = {
    CardName: PropTypes.string,
    TitleOnClick: PropTypes.func
};

const animateStyle = {
    animation: `fade-in-up ${easings.easeInOutExpo} 150ms`
};

/**
 * React Card component used to render the Product card details in home page
 * @param {*} props : expecting Product name , publisher, and description as input from the Parent component
 * @returns An animated card component visualizes the product/software minimalistic details
 */
const OprCardDetails = (props) => {
    return (
        <Card className={`${styles['card']} ${animateStyle}`} >
            <CardContent className={styles['card__content']}>
                <Grid container columns={24} sx={{ flexWrap: 'nowrap' }}>
                    <Grid item xs={8} className={styles['card__item']} style={{ justifyContent: 'center', display: 'grid' }}>
                        <Box className={styles['card__imageContainer']}>
                            <Avatar
                                className="product-icon"
                                sx={{ bgcolor: '#FFDC78', width: 100, height: 100 }}
                                alt={props.avatarAlt}
                                src={props.avatarSrc}
                            >
                            </Avatar>
                        </Box>
                    </Grid>
                    <Grid item xs={16} className={styles['card__item']} style={{ paddingLeft: '10px', alignContent: 'center', display: 'grid' }}>
                        <p className={styles['card_name'] }>
                            {props.CardName}
                        </p>
                        {props.children}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

OprCardDetails.propTypes = propTypes;
export default OprCardDetails;