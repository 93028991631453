import React from 'react';
import error from '../../../../assets/Images/error.svg';
import SubHeader from '../../../../components/SubHeader/index';
import { breadcrumbPasswordReset } from '../../../../constants';

export function ErrorPage() {
    return (
        <div>
            <SubHeader breadcrumbs={breadcrumbPasswordReset} />
            <div className="bg-panel">
                <div className="card-panel">
                    <div className="text-left">
                        <div className="msg-icon"><img alt="error Details" src={error} /></div>
                        <div className="msg-text">Unfortunately, We Are Unable To Authenticate You.</div>
                    </div>
                    <div className="textAlignCenter assistance-text">
                        Please Call Technology Connection
                        {' '}
                        <span className="assistance-number"> 1.800.999.9999 </span> For Further Assistance
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ErrorPage;
