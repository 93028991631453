import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, Card, Link } from '@mui/material';
import SubHeader from '../../../components/SubHeader';
import { breadcrumbRSA } from '../../../constants';


export default function SecureId() {

    return (
        <>
            <SubHeader breadcrumbs={breadcrumbRSA} />
            <Box sx={{ margin: '1rem', marginTop: '1.5rem' }}>
                <Card >
                    <Grid container style={{ justifyContent: 'left', minHeight: '100px', margin: '1rem', marginTop: '1.5rem' }}>
                        <Link href="#" style={{width:"100%"}}>Get RSA Token</Link>
                        <Link href="#" style={{width:"100%"}}>RSA Token change</Link>
                        <Link href="#" style={{ width: "100%" }}>RSA PIN Reset</Link>
                    </Grid>
                </Card> 
            </Box>
        </>
    )
}
