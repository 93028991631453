import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    loadTile:PropTypes.any,
    setActiveStrips:PropTypes.any,
    setStripLabel:PropTypes.any,
};

GetStarted.propTypes = propTypes;

export function GetStarted({ loadTile, setActiveStrips, setStripLabel }) {

    useEffect(() => {
        setActiveStrips(1);
        setStripLabel('Forgot Password')
    }, []);

    function loadChallengeData2() {
        loadTile("ChallengeData2");
    }

    return (
        <div>
            <div>
                <div className="bg-panel">
                    <div className="card-panel">
                        <div className="step">
                            <span>Step 1</span>
                        </div>
                        <div className="step-instruction">
                            Using your Wells Fargo device, enter 'sdhelp' in the username field and select Forgot Password.
                        </div>
                        <div className="step">
                            <span>Step 2</span>
                        </div>
                        <div className="step-instruction">
                            Select Challenge Response on the next screen on your Wells Fargo device.
                        </div>
                    </div>
                    <div className="btn-container">
                        <input type="button" className="button-primary" value="Next" onClick={() => loadChallengeData2()} />
                    </div>
                </div>
            </div>
        </div>
    );
}


