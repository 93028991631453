import CryptoJS from 'crypto-js';

const  maskCode= 'maskingPart';

export const setItem=(name,data)=> { 
    const encrypted = CryptoJS.AES.encrypt((data), maskCode); 
    localStorage.setItem(name, encrypted); 
}

export const getItem = (name) => { 
    const encrypted = localStorage.getItem(name); 
    if(encrypted===null) return null;
    const decrypted = CryptoJS.AES.decrypt(encrypted, maskCode).toString(CryptoJS.enc.Utf8); 
    return decrypted;
}
