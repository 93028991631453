import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  loaderDetails: false,
  message: ""
};

export const loaderSlice = createSlice({
  name: 'loaderDetails',
  initialState,

  reducers: {
    showLoader: ({ payload }) => {
      return {
        loaderDetails: true,
        message: payload
      }
    },

    hideLoader: () => {
      return {
        loaderDetails: false,
      }
    }
  }
});

export const { showLoader, hideLoader } = loaderSlice.actions;
export const loadingSelector = (state) => state.loaderDetails;
export default loaderSlice.reducer;
