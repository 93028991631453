import React, { useEffect, useState } from 'react';
import Notifier from '../Notifier';
import { useSelector, useDispatch } from 'react-redux';
import { SUCCESS, FAILURE, ExceptionHandlers } from '../../constants';
import {
    successLoadingStatus,
    showErrorNotification
} from '../../redux/Notification';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';


const propTypes = {
    error: PropTypes.any,
};
/**
 * React component used to display a notification on the application tp right. Especially Error, Order Success etc
 * This component maily update the Reduce slice to determine the action is success/failure .Based on it, internally Triggers the notifier component
 * @param {*} props : Parameters to indicate the Notification for error or success
 * @returns Notification component
 */
const Notification = (props) => {
    const { error } = props;
    const { isError, isSuccess, successMessage, infoMessage, isInfo, errorDetails, errorMessage } = useSelector(successLoadingStatus);
    const dispatch = useDispatch();
    let notifyDetails = {};

    useEffect(() => {
        error && dispatch(showErrorNotification(error));
    }, [dispatch, error]);


    if (isSuccess) {
        notifyDetails = { type: SUCCESS, header: ExceptionHandlers.success, Message: successMessage };
    }
    else if (isError) {
        notifyDetails = {
            type: FAILURE,
            header: ExceptionHandlers.error,
            Message: (errorDetails !== null && typeof errorDetails === 'string') ? errorDetails : ExceptionHandlers.customErrorMessage
        };
    } else if (isInfo) {
        notifyDetails = { type: 'info', header: ExceptionHandlers.error, Message: infoMessage };
    }

    return (
        <>
            {(isError || error || isSuccess || isInfo) && (
                <Notifier
                    status={true}
                    type={notifyDetails?.type}
                    Header={notifyDetails?.header}
                    Message={(() => {
                        const Content = () => {
                            const [details, setDetails] = useState(false);
                            if (isError && errorDetails?.Error?.Message) {
                                return (
                                    <div>
                                        {errorDetails.Error.Message}.
                                        <br />
                                        {' '}
                                        <Button variant="text" color="error" onClick={() => { setDetails(!details) }}>{details ? 'Hide Details' : 'Show Details'}</Button>

                                        {details &&
                                            <>
                                                <p>Trace Id   : {errorDetails.TraceId}</p>
                                                <p>Error Code : {errorDetails.Error.Code}</p>
                                            </>
                                        }
                                    </div>
                                );
                            }

                            else {
                                if ((isError === true)) {
                                    return (errorMessage !== null ? errorMessage : ExceptionHandlers.customErrorMessage)
                                }
                                else if (isSuccess === true) {
                                    return (successMessage !== null ? successMessage : ExceptionHandlers.customSuccessMessage)
                                }
                                else if (isInfo === true) {
                                    return (infoMessage !== null ? infoMessage : ExceptionHandlers.customSuccessMessage)
                                }
                            }
                        };
                        return <Content />;
                    })()}
                />

            )}
        </>
    );
};


Notification.propTypes = propTypes;
export default Notification;
