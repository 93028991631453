import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    loadTile:PropTypes.any,
    tempPassword:PropTypes.any,
    setActiveStrips:PropTypes.any,
    setStripLabel:PropTypes.any, 
    isSecureDoc:PropTypes.any, 
};

TemporaryPassword.propTypes = propTypes;

export function TemporaryPassword({ loadTile, tempPassword, setActiveStrips, setStripLabel, isSecureDoc }) {
    let step=(isSecureDoc?5:1);
    useEffect(() => {
        setActiveTile();
        setStripLabel('Temporary Password');
    }, []);

    function showTempPasswordCriteria() {
        loadTile("PasswordCriteria")
    }

    function setActiveTile() {
        if (isSecureDoc) {
            setActiveStrips(4);
        } else {
            setActiveStrips(1);
        }
    }

    return (
        <div>
            <div className="bg-panel">
                <div className="card-panel">
                    <div className="step">
                        <span>Step {step}</span>
                    </div>
                    <div className="step-instruction">
                        Enter your AD-ENT username and the temporary password shown below into the Windows login screen on your Wells Fargo computer.
                    </div>
                    <div className="display-group">
                        <label htmlFor="code">Temporary Password</label>
                        <div className="code">
                            <label>{tempPassword}</label>
                        </div>
                    </div>
                    <div className="pwd-hint">
                        Password is case sensitive
                    </div>
                </div>

                <div className="btn-container">
                    <input type="button" className="button-primary" value="Continue" onClick={() => showTempPasswordCriteria()} />
                </div>

                <div className="text-center reset-instruction">
                Once you log in using the temporary password, you can set your new password.
                </div>
            </div>
        </div>
    );
}
