import React, { useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import { Header, Notification } from './components';
import './App.css';
import './Custom.css';
import Login from './pages/MainApp/Login';
import DWPSApps from './pages/MainApp/DWPSApps';
import Faq from './pages/MainApp/FAQ';
import { ROUTER_ITEMS } from './constants';
import { useIdleTimer } from 'react-idle-timer';
import { unregister } from './registerServiceWorker';
import store from './store/index.js';
import './polyfills';
import { LandingPage } from './pages/PasswordReset/LandingPage/index';
import ErrorPage from './pages/PasswordReset/Common/Error/index';
import { ErrorBoundary } from 'react-error-boundary';
import { SnackbarProvider, useSnackbar } from 'notistack';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ProtectedRoute from './util/protectedRoute';
import { clearTokenStatus, logOff } from "./redux/PasswordReset/Slice.jsx";
import PropTypes from 'prop-types';
import moment from 'moment';
import SecureId from './pages/RSA/SecureId/index';

const propTypes = {
    snackbarKey: PropTypes.any,
};

function SnackbarCloseButton({ snackbarKey }) {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton aria-label='btnIcon' onClick={() => closeSnackbar(snackbarKey)}>
            <CloseRoundedIcon />
        </IconButton>
    );
}

const AppComponent = () => {
    const dispatch = useDispatch();
    dispatch(clearTokenStatus());
    const isActive = window.localStorage.getItem('isActive');

    const handleOnLogout = () => {
        if (location.pathname !== "/" && location.pathname !== "/true") {
            dispatch(logOff(true));
        }
    }


    const handleOnIdle = () => {
        if (location.pathname !== "/" && location.pathname !== "/true") {
            dispatch(logOff(true));
        }
    }

    useIdleTimer({
        timeout: 1000 * 60 * 15,
        onIdle: handleOnIdle,
        debounce: 500
    });

    useEffect(() => {
        const interval = setInterval(() => {
            if (isActive) {
                const currentTiming = new moment();
                const maxTiming = new moment(window.localStorage.getItem('lTime'));
                if (moment(currentTiming).isAfter(maxTiming)) {
                    handleOnLogout();
                }
            }
        }, 2000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Box>
                <Header appName="Modern Workspace" routerItems={ROUTER_ITEMS} />
            </Box>
            <Box sx={{ flex: '1 1 auto', overflowY: 'auto' }}>
                <ErrorBoundary FallbackComponent={Notification}>
                    <SnackbarProvider maxSnack={1} action={snackbarKey => SnackbarCloseButton(snackbarKey)}>
                        <Notification></Notification>
                        <Routes>
                            <Route path="/" element={<Login />} />
                            <Route path="/:sessionout" element={<Login sessionout={false} />} />
                            <Route path='/apps' element={<ProtectedRoute><DWPSApps /></ProtectedRoute>} />
                            <Route path='/faq' element={<ProtectedRoute><Faq /></ProtectedRoute>} />
                            <Route path="/passwordreset/landingpage" element={<ProtectedRoute><LandingPage /></ProtectedRoute>} />
                            <Route path="/rsa/secureid" element={<ProtectedRoute><SecureId /></ProtectedRoute>} />
                            <Route path="/passwordreset/error" element={<ProtectedRoute><ErrorPage /></ProtectedRoute>} />
                        </Routes>
                    </SnackbarProvider>
                </ErrorBoundary>
            </Box>
        </Box>
    )
}

const App = () => {
    const baseUrl = '/';
    return (
        <BrowserRouter basename={baseUrl}>
            <Provider store={store}>
                <AppComponent />
            </Provider>
        </BrowserRouter>
    );
}

unregister();
SnackbarCloseButton.propTypes = propTypes;
export default App;
