import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid, FormControl, InputLabel, OutlinedInput, FormHelperText } from '@mui/material';
import WF_LOGO from '../../../assets/Images/WF_Logobox.svg';
import { WfButton } from '../../../components';
import { Helmet } from "react-helmet";
import alert from '../../../assets/Images/messaging_icon.svg';
import $ from 'jquery';
import MuiAlert from '@mui/material/Alert';
import { useParams, useNavigate } from "react-router-dom";
import { getJWTToken, selfServiceSelector, validateToken, runOktaAuthentication } from "../../../redux/PasswordReset/Slice";
import { useSelector, useDispatch } from 'react-redux';
import { showErrorNotification, showSuccessNotification,showInfoNotification } from '../../../redux/Notification';
import { loadingSelector } from '../../../redux/Loader';
import Skeleton from '@mui/material/Skeleton';
import { setItem } from '../../../util/localStorage';
import moment from 'moment';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Login() {
    const BaseURL = process.env.REACT_APP_API_BASE_URL;
    const CaptchaURL = process.env.REACT_APP_CAPTCHA_URL;
    const { loaderDetails } = useSelector(loadingSelector);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showNoValidEmail, setshowNoValidEmail] = useState(false);
    const [hardLoader, setHardloader] = useState(false);
    const [emailId, setEmailId] = useState('');
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const { sessionout } = useParams();
    const [open, setOpen] = useState(sessionout);
    const [errorMessage, setErrorMessage] = useState("");
    const [sessionId, setSessionId] = useState(null);
    const isActive = window.localStorage.getItem('isActive');
    const { isAuthenticated } = useSelector(selfServiceSelector);
    const urlParams = new URLSearchParams(window.location.search);
    const [userText, setUserText] = useState("");
    const handleUserKeyPress = useCallback(event => {
        const { key, keyCode } = event;
        if (keyCode === 13) {
            setUserText(prevUserText => `${prevUserText}${key}`);
        }
    }, []);
    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    useEffect(() => {
        $('.nucaptcha-answer').on('keypress', function (e) {
            let key = e.which;
            if (key === 13) {
                validateEmail(e);
            }
        });
    });

    useEffect(() => {
        if(open){
            dispatch(showInfoNotification("You're being redirected to login page due to session time out."));
            setOpen(!open);
            return navigate('/');
        }
        else if (urlParams.has("state") && urlParams.has("code")) {
            setHardloader(true);
            return dispatch(getJWTToken(urlParams.get("state"), urlParams.get("code")));
        }
        else if (urlParams.has("error")) {
            dispatch(showErrorNotification(urlParams.get("error")));
            return navigate('/');
        }
        else if (urlParams.has("unauthorized")) {
            dispatch(showErrorNotification("This application is restricted to authorized users."));
            return navigate('/');
        }
        else if (urlParams.has("tokenexpire")) {
            dispatch(showErrorNotification("Re-Login required to continue further."));
            return navigate('/');
        }
        else if (urlParams.has("logout")) {
            dispatch(showSuccessNotification("you have successfully logged out."));
            return navigate('/');
        }
        else {
            return null;
        }
    }, []);

    useEffect(() => {
        dispatch(validateToken());

        $(function () {
            $('#nucaptcha-answer').addClass('custom-txtbox');
            $('#media-container').addClass('captcha-img');
            $('#nucaptcha-answer').attr("placeholder", "Enter Captcha");
        });

        fetch(`${BaseURL}api/v1/user/sessionId`)
            .then(function (body) {
                return body.text();
            }).then(function (data) {
                setSessionId(data)
                window.localStorage.setItem('sessionId', data);
                let captchaBaseUrl = CaptchaURL;
                let idx = captchaBaseUrl.indexOf("/ATADUN");
                let jsURI = captchaBaseUrl.substring(0, idx);
                (function (w, d, s, u, q, js, fjs, nds) {
                    nds = w.ndsapi || (w.ndsapi = {});
                    nds.config = {
                        q: [],
                        ready: function (cb) {
                            this.q.push(cb)
                        }
                    };
                    js = d.createElement(s);
                    fjs = d.getElementsByTagName(s)[0];
                    js.src = jsURI + "/jenny/nd";
                    fjs.parentNode.insertBefore(js, fjs);
                    js.onload = function () {
                        nds.load(captchaBaseUrl);
                    }
                }(window, document, "script", captchaBaseUrl));
                let nds = window.ndsapi;
                nds.config.ready(function () {
                    nds.setPlacement("EmpSelfService");
                    nds.setPlacementPage(1);
                    nds.setSessionId(data);
                });
            }).catch(e => {
                dispatch(showErrorNotification("Unable to reach API.Please try After Some time."));
            });
        $('#PRRoot').html('<script id="nucaptcha-template" type="text/x-nucaptcha-template">${PlayerStart} ${Media} <p id="new-challenge-link">${CommandNewChallenge} ${CommandPlayerMode}</p>' +
            '<div id="my-nucaptcha-answer-wrapper">${DirectionsVerbose} ${InputAnswer} </div>${PlayerEnd}</script>');
    }, []);

    const validateEmail = (event) => {
        event.preventDefault();
        let form = document.getElementById('myForm');
        let formData = new FormData(form);
        if (emailId?.match(isValidEmail)) {
            setshowNoValidEmail(false);
            setItem('username', emailId);
            let URL = BaseURL.concat('api/v1/user/captcha');
            $.ajax({
                type: "post",
                url: URL,
                data: formData,
                processData: false,
                contentType: false,
                headers: { "SessionId": window.localStorage.getItem('sessionId') }
            }).done(function (data) {
                if (data.includes("div")) {
                    $('#captchaCard').removeClass('hideDiv');
                    $('#captchaCard').addClass('showDiv');
                    $('#captchaCard').html(data);
                }
                else {
                    dispatch(runOktaAuthentication(emailId));
                };
            });

        }
        else {
            if (emailId !== "") {
                setErrorMessage("Please enter a valid email id");
            }
            else {
                setErrorMessage("Please enter correct email id");
            }
            setshowNoValidEmail(true);
        }
    }

    const handleEmailEnter = (event) => {
        setEmailId(event.target.value);
        setshowNoValidEmail(false);
        if (event.key === 'Enter') {
            validateEmail(event);
        }
    }

    return (
        <>
            <Helmet>
                <title>Self Service Portal - Login</title>
                <Helmet><script src="~/lib/jquery/dist/jquery.js"></script></Helmet>
            </Helmet>
            {(() => {
                if (isActive && isAuthenticated) {
                    return navigate("/apps");
                }
                else {
                    return (
                        <>
                            {((loaderDetails || hardLoader) &&
                                <Box sx={{ display: 'flex', margin: '1rem', justifyContent: 'center' }}>
                                    <Skeleton animation="wave" height="35rem" width="45rem" style={{ marginBottom: 6, transform: "inherit" }} />
                                </Box>
                            )}
                            {(
                                <Box sx={{ display: (loaderDetails || hardLoader) ? 'none' : 'flex', margin: '1rem', justifyContent: 'center' }}>
                                    <Grid container sx={{
                                        justifyContent: 'center', alignItems: 'center',
                                        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
                                        height: '35rem', background: 'white', maxWidth: '45rem', display: 'block', textAlign: 'center', padding: '1rem', borderRadius: '4px',
                                    }}>
                                        <div id="PRRoot"></div>
                                        {sessionId &&
                                            <Grid container style={{ display: 'flex' }}>
                                                <Grid item xs={12}>
                                                    <img loading="eager" alt="wellsfargo logo" width='62px' height='62px' src={WF_LOGO} />
                                                </Grid>
                                                <Grid item xs={12} style={{ justifyItems: 'center', display: 'grid', marginTop: '20px' }}>
                                                    <form action="post" id="myForm" onSubmit={(e) => validateEmail(e)}>
                                                        <div className="">
                                                            <br />
                                                            <FormControl sx={{ width: 'calc(min(100%, 38rem))' }} >
                                                                <InputLabel htmlFor="component-error">Wells Fargo Email</InputLabel>
                                                                <OutlinedInput
                                                                    id="component-error"
                                                                    aria-describedby="component-error-text"
                                                                    value={emailId}
                                                                    label="Wells Fargo Email"
                                                                    name="UserName"
                                                                    onChange={(e) => handleEmailEnter(e)}
                                                                    error={showNoValidEmail}
                                                                    inputProps={{ "type": "text", }}
                                                                />
                                                                {showNoValidEmail && <FormHelperText error={showNoValidEmail} id="component-error-text">{errorMessage}</FormHelperText>}
                                                            </FormControl>
                                                            <input type="hidden" name="SessionId" value={sessionId} />
                                                        </div>
                                                        <div id="captchaCard" className="card-panel captchaControls hideDiv" />
                                                        <div className="btn-container textAlignCenter">
                                                            <br />
                                                            <WfButton data-testid="btnEmail" type='button' variant='contained' text='Next'
                                                                onClick={(e) => validateEmail(e)}></WfButton>
                                                        </div>
                                                    </form>


                                                </Grid>
                                            </Grid>
                                        }

                                        <Grid container style={{ textAlign: 'center', display: 'flex',justifyContent: 'center'}}>
                                            <Box sx={{ width: '90%', borderRadius: '12px', backgroundColor: '#FBF6EB', padding: '0.05rem' }}>
                                                <Grid container item xs={12}>
                                                    <Grid item xs={1} sx={{alignSelf: 'center'}}>
                                                        <img alt='QR code' width='28px' height='28px' src={alert} />
                                                    </Grid>
                                                    <Grid item xs={11} style={{ padding: '16px', margin: '0px', textAlign: 'left' }}>
                                                        <h4 style={{margin: '5px',marginLeft: '0px',fontSize:'bold',marginTop:'0px'}}>Disclaimer:</h4>
                                                        <h5 style={{margin: '0px'}}>
                                                        To access the Self Service Portal, you need to have multi-factor authentication enabled.
                                                        </h5>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>

                                    </Grid>
                                    <Box>
                                    </Box>
                                </Box>
                            )}
                        </>
                    )
                }
            })()}
        </>
    )
}
