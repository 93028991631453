import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

const propTypes = {
    setActiveStrips:PropTypes.any,
    setStripLabel:PropTypes.any,
    isSecureDoc:PropTypes.any,
};

PulseSecureInstruction.propTypes = propTypes;

export function PulseSecureInstruction({ setActiveStrips, setStripLabel, isSecureDoc }) {
    const navigate = useNavigate();
    let step=(isSecureDoc?7:3);
    function setActiveTile() {
        if (isSecureDoc) {
            setActiveStrips(6);
        } else {
            setActiveStrips(3);
        }
    }

    useEffect(() => {
        setActiveTile();
    setStripLabel('Pulse Secure Login')
    }, []);

    return (
        <div>
            <div className="bg-panel">
                <div className="card-panel">
                    <div className="step">
                        <span>Step {step}</span>
                    </div>
                    <div className="step-instruction">
                    You have successfully reset your password. If you are working remotely, connect to the Wells Fargo VPN so that your password is synchronized across systems.
                    </div>
                </div>

                <div className="btn-container">
                    <input type="button" className="button-primary" value="Done" onClick={() => navigate('/apps')} />
                </div>
            </div>
        </div>
    );
}
